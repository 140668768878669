import request from "@/utils/request";
//发送验证码
export function sendMessageCode(data) {
  return request({
    url: `sendMessageCode?phoneNumber=${data.phone}`,
    method: "post",
  });
}
//校验验证码
export function checkMessageCode(data) {
  return request({
    url: `checkMessageCode?phoneNumber=${data.phone}&verifyCode=${data.messageCode}`,
    method: "post",
  });
}
//手机号注册
export function registerByPhone(data) {
  return request({
    url: `registerByPhone?phoneNumber=${data.phone}&verifyCode=${data.messageCode}`,
    method: "post",
  });
}
