<template>
  <div class="register-container">
    <div class="banner">
      <div class="banner_img">
        <h2>{{ $t("L_REGISTER.BANNER_0001") }}</h2>
      </div>
    </div>
    <div class="register-form">
      <el-form ref="registerForm" :model="registerForm" :rules="rules">
        <p class="tip">
          {{ $t("L_REGISTER.TIP_0001") }}
          <router-link to="/login" tag="a">{{
            $t("L_REGISTER.TIP_0002")
          }}</router-link>
        </p>
        <el-input type="text" class="autofill"></el-input>
        <el-input type="password" class="autofill"></el-input>
        <el-form-item prop="phone">
          <el-input
            type="text"
            v-model="registerForm.phone"
            :placeholder="$t('L_REGISTER.FORM_0004')"
            ref="phone"
            @focus="handleFocus('phone')"
            @blur="handleBlur('phone')"
            auto-complete="off"
          ></el-input>
          <div class="form_tip" v-if="isFormTip4">
            {{ $t("L_REGISTER.TIP_0014") }}
          </div>
          <div class="server_tip" v-if="isServerTip2">
            {{ $t("L_REGISTER.TIP_0016") }}
          </div>
        </el-form-item>
        <el-form-item prop="verificationCode" class="btn">
          <el-input
            type="text"
            v-model="registerForm.verificationCode"
            :placeholder="$t('L_REGISTER.FORM_0008')"
            auto-complete="off"
          >
            <template slot="append">
              <el-button
                v-if="registerForm.showloginCode"
                type="primary"
                @click="getloginPhoneCode"
                >{{ $t("L_LOGIN.BUTTON_0005") }}</el-button
              >
              <el-button v-else type="primary"
                >{{ registerForm.count }}s</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="accept"
          class="agree-item"
          :class="currentLang === 'en' ? 'agree-item-e' : ''"
        >
          <el-checkbox v-model="registerForm.accept"
            >{{ $t("L_REGISTER.TIP_0026") }}
            <router-link to="/protocol" tag="a" target="_blank">{{
              $t("L_REGISTER.TIP_0027")
            }}</router-link>
          </el-checkbox>
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button
            type="primary"
            native-type="submit"
            :loading="registering"
            @click.native.prevent="handleSubmit"
            class="btn"
            >{{ $t("L_REGISTER.BUTTON_0001") }}</el-button
          >
          <!--        <div class="but_tip">-->
          <!--          {{ $t("L_REGISTER.TIP_0029") }}-->
          <!--        </div>-->
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { moveToErr } from "@/utils/index";
import { sendMessageCode, registerByPhone } from "@/api/register";
export default {
  name: "register",
  data() {
    let validatePhone = (rule, value, callback) => {
      let reg = /^((0\d{2,3}\d{7,8})|(1[358479]\d{9}))$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_REGISTER.TIP_0015")));
      } else {
        callback();
      }
    };
    let validateCode = (rule, value, callback) => {
      let reg = /^\d{6}$/;
      if ((value && !reg.test(value)) || value === "") {
        callback(new Error(this.$t("L_REGISTER.TIP_0025")));
      } else {
        callback();
      }
    };
    let validateAccept = (rule, value, callback) => {
      if (value === false) {
        callback(new Error(this.$t("L_REGISTER.TIP_0028")));
      } else {
        callback();
      }
    };
    return {
      // 表单参数
      registerForm: {
        phone: "",
        showloginCode: true,
        count: 60,
        verificationCode: "", //表单中展示的验证码
        agree: false,
        timer: null,
        accept: false,
      },
      rules: {
        phone: [
          {
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        verificationCode: [{ validator: validateCode, trigger: "blur" }],
        accept: [
          {
            validator: validateAccept,
            trigger: "change",
          },
        ],
      },
      registering: false,
      isFormTip4: false,
      isFormTip8: false,
      isServerTip2: false,
    };
  },
  computed: {
    currentLang() {
      let language = this.$i18n.locale;
      return language;
    },
  },
  methods: {
    handleFocus(param) {
      this.$refs["registerForm"].clearValidate([param]);
      if (param === "phone") {
        this.isFormTip4 = true;
      } else if (param === "validate") {
        this.isFormTip8 = true;
      }
    },
    handleBlur(param) {
      if (param === "phone") {
        this.isFormTip4 = false;
      } else if (param === "validate") {
        this.isFormTip8 = false;
      }
    },
    // 登录
    handleSubmit() {
      this.registering = true;
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          registerByPhone({
            phone: this.registerForm.phone,
            messageCode: this.registerForm.verificationCode,
          }).then((res) => {
            if (res.code === 200) {
              this.registering = false;
              this.$message({
                message: this.$t("L_REGISTER.TIP_0030") + res.data,
                type: "success",
                offset: 60,
                duration: 5000
              });
              this.resetForm("registerForm");
              setTimeout(() => {
                this.$router.push({ name: "login" });
              }, 1500);
            } else {
              this.registering = false;
              this.resetForm("registerForm");
              this.resetRegisterForm();
              this.$message({
                message: res.msg,
                type: "error",
                offset: 60,
              });
            }
          });
        } else {
          // 校验不通过自动定位到不通过的表单项
          moveToErr();
          this.registering = false;
        }
      });
    },
    // 获取手机验证码
    getloginPhoneCode() {
      this.$refs.registerForm.validateField("phone", async (valid) => {
        if (!valid) {
          // 获取验证码
          sendMessageCode({ phone: this.registerForm.phone })
            .then((res) => {
              if (res.code != 200) {
                if (this.language === "en") {
                  return this.$message.error(
                    "Operation failed, please try again later!"
                  );
                } else {
                  return this.$message.error("验证码发送失败！");
                }
              } else {
                // 当验证码发送成功，开始60秒倒计时
                if (!this.registerForm.timer) {
                  this.registerForm.showloginCode = false;
                  this.registerForm.count = 60;
                  this.registerForm.timer = setInterval(() => {
                    this.registerForm.count--;
                    if (this.registerForm.count <= 0) {
                      this.resetRegisterForm();
                    }
                  }, 1000);
                } else {
                  this.resetRegisterForm();
                }
              }
            })
            .catch(() => {
              if (this.language === "en") {
                return this.$message.error(
                  "Operation failed, please try again later!"
                );
              } else {
                return this.$message.error("验证码发送失败！");
              }
            });
        } else {
          return false;
        }
      });
    },
    resetRegisterForm() {
      if (!this.registerForm.timer) {
        clearInterval(this.registerForm.timer);
        this.registerForm.timer = null;
      }
      this.registerForm.showloginCode = true;
      this.registerForm.count = 60;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  beforeDestroy() {
    clearInterval(this.registerForm.timer);
    this.registerForm.timer = null;
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/register";
</style>
